import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import EventPost from "../components/event-post";

import SEO from "../components/seo";

import { injectIntl } from "gatsby-plugin-react-intl";

const EventPostPage = ({ data, location, pageContext: { localizedSlugs = {} } }) => {
  const { eventName } = data?.contentfulTapahtuma;

  return (
    <Layout
      data={data}
      headerClasses="absolute lg:static"
      mainClasses="lg:p-0 lg:max-w-none"
      localizedSlugs={localizedSlugs}
    >
      <SEO title={eventName} />
      <EventPost data={data} location={location} />
    </Layout>
  );
};

export default injectIntl(EventPostPage);

export const pageQuery = graphql`
  query($slug: String!, $locale: String) {
    contentfulTapahtuma(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      __typename
      eventName
      eventCancellation
      isEventRepeating
      slug
      headImageAlt
      headImage {
        gatsbyImageData(layout: CONSTRAINED, height: 1080)
      }
      headImageDescription {
        headImageDescription
      }
      headImageAltVertical
      headImageVertical {
        gatsbyImageData(layout: CONSTRAINED, width: 1280)
      }
      headImageDescriptionVertical
      ticketLinks {
        ... on Node {
          ... on ContentfulLippuLinkki {
            id
            ticketLinkName
            ticketLink
          }
        }
      }
      ticketDescription {
        ticketDescription
      }
      ticketAdditionalInfo {
        ticketAdditionalInfo
      }
      freeEntry
      eventDescription {
        eventDescription
      }
      description {
        raw
        references {
          ...RichTextReferencesFragment
        }
      }
      openingHours {
        mondayFrom
        mondayTo
        tuesdayFrom
        tuesdayTo
        wednesdayFrom
        wednesdayTo
        thursdayFrom
        thursdayTo
        fridayFrom
        fridayTo
        saturdayFrom
        saturdayTo
        sundayFrom
        sundayTo
      }
      eventLocations
      locationDetails {
        locationDetails
      }
      operators {
        ... on Node {
          ... on ContentfulToimija {
            id
            operatorName
            operatorWebsite
            operatorLogo {
              gatsbyImageData(layout: FIXED, width: 90, height: 90)
            }
            isExternal
          }
        }
      }
      eventDates {
        ... on Node {
          ... on ContentfulTapahtumanPaivamaarat {
            id
            startDate
            endDate
          }
        }
      }
      dateNote {
        dateNote
      }
      categories {
        ... on Node {
          ... on ContentfulKategoria {
            id
            categoryName
          }
        }
      }
      senseCategories {
        ... on Node {
          ... on ContentfulAistikategoria {
            id
            categoryName
          }
        }
      }
      eventFacebookLink
      facebookLink
      instagramLink
      twitterLink
      eventWebsite
    }
    ...AllFragment
  }
`;
